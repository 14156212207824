document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('[data-menu-toggle]').forEach(el => {
       el.addEventListener('click', e => {
           e.preventDefault();
           document.querySelector('.menu-popup').classList.toggle('is-open');
       });
    });

    document.querySelectorAll('[data-menu-mobile-dropdown]').forEach(el => {
        el.addEventListener('click', function (e) {
            e.preventDefault();
            this.classList.toggle('is-open');
            $(this.closest('li').querySelector('ul')).slideToggle(300);
        });
    });
});